export default {
    getPWAStatus: state => state.isPWA,
    getActiveSearchTab: state => state.activeSearchTab,
    getSearchTabs: state => state.searchTabs,
    isActiveSearchTab: state => tabToCheck => (state.searchTab == tabToCheck),
    getSearchResults: state => state,
    getCurrentPage: state => state.page || 1,
    getRecordsPerPage: state => state.recordsPerPage || 15,
    getSearchResultsField: state => field => state[field] || '',
    getBulkSelectedRecords: state => state.bulkSelectedRecords,
    getLoadingStatus: state => state.loading,
    getLoadMoreLoadingStatus: state => state.loadingLoadMore,
    getSearchProgressStatus: state => state.searchProgressStatus,
    isLiteSubscription : state =>state.isLiteSubscription,
    getSearchPageUrl: state => state.searchPageUrl,
    isSavedSearch : state => state.isSavedSearch,
    getCanReset: state => {
        let value = false;
        if (typeof (state['canReset']) == "undefined") {
            return value;
        }
        return state.canReset;
    },
    getSearchResultsFilterValue: state => filter => {
        let values = '';
        if (typeof (state['filters']) == "undefined" || typeof (state['filters'][filter]) == "undefined" || state['filters'][filter] == null) {
            return values;
        }
        return state['filters'][filter].value;
    },
    getSearchResultsFilterOptions: state => filter => {

        let values = [];
        if (typeof (state['filters']) == "undefined" || typeof (state['filters'][filter]) == "undefined"  || state['filters'][filter] == null) {
            return values;
        }
        return state['filters'][filter];
    },
    getSearchResultsFilterSelectedValues: state => filter => {
        let values = [];
        if (typeof (state['filters']) == "undefined" || typeof (state['filters'][filter]) == "undefined"  || state['filters'][filter] == null) {
            return values;
        }
        for (let value of state['filters'][filter]) {
            if (value.selected) {
                values.push(String(value.id))
            }
        }
        return values;
    },
    //standards getters
    getStandardsFilter: state => field => state.standards[field],
    getToolbarActions: state => state.actions,
    getShowToolbar: state => state.showToolbar,
    getDocRowActionStatus: state => action => rowIndex => {
        let processingStatus = (typeof(state.records[rowIndex][action]) == "undefined")  ? 0 : state.records[rowIndex][action];
        return processingStatus;
    },
    getDocRowActions: state => rowIndex => {
        let actions = (typeof(state.records[rowIndex]['actions']) != "undefined")  ? state.records[rowIndex]['actions'] : {};
        return actions;
    },
    getSearchCriteria: (state, getters) => activeTab => {
        let criteria = {};
        switch(activeTab) {
            case 'standards':
                criteria = getters.getStandardSearchFitlers;
                break;
            case 'collections':
                criteria = getters.getCollectionSearchFilters;
                break;
            default:
                break;

        }    
        let initialQuery = getters.getSearchResultsField('initialQuery');
        for (const [key, value] of Object.entries(initialQuery)) {
            criteria[key] = value;
        }
        criteria['page'] = getters.getCurrentPage;
        criteria['orderBy'] = getters.getSearchResultsField('orderBy');
        return criteria;
    },
    getStandardSearchFitlers: (state, getters) => {
        let criteria = {
            'filter-publisher': getters.getSearchResultsFilterSelectedValues('publisher').join(','),
            'filter-country': getters.getSearchResultsFilterSelectedValues('country').join(','),
            'filter-language': getters.getSearchResultsFilterSelectedValues('language').join(','),
            'filter-include': getters.getSearchResultsFilterSelectedValues('include').join(','),
            'filter-year': getters.getSearchResultsFilterSelectedValues('year').join(','),
            'filter-xref': getters.getSearchResultsFilterValue('query5'),
            'filterby': getters.getSearchResultsFilterSelectedValues('filterby').join(','),
        };
        return criteria;
    },
    getCollectionSearchFilters: (state, getters) => {
        let criteria = {
            'filter-colls': getters.getSearchResultsFilterSelectedValues('colls').join(','),
            'from_collection': getters.getSearchResultsField('fromCollection'),
            'filter-publisher': getters.getSearchResultsFilterSelectedValues('publishers').join(','),
            'filter-dcoll': getters.getSearchResultsFilterSelectedValues('dcolls').join(','),
            'filter-include': getters.getSearchResultsFilterSelectedValues('include').join(','),
            'filter-type': getters.getSearchResultsFilterSelectedValues('types'),
            'filter-tags': getters.getSearchResultsFilterSelectedValues('tags').join('|'),
        };
        return criteria;
    },
    getExportStatus: state => state.exportStatus,
    getExportResult: state => state.exportResult,
    getSelectedFieldsToExport: state => state.selectedfieldsForExport,
    getExportCsvSeparator: state => state.exportCsvSeparator,
    isSpecialSearch: state =>  {
        return (state.searchTerms.substring(1,7)  == 'STATS:' || state.searchTerms.substring(1,7)  == 'DOCID:'
        || state.searchTerms.substring(1,5)  == 'ORD:' || state.searchTerms.substring(1,5)  == 'REQ:');
    }

}